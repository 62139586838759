/* Footer.css */

.footer {
    background-color: #282c34;
    color: #ffffff; 
    padding: 20px 0;
    font-family: 'Arial', sans-serif; 
}

.footer-container {
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-around;
    max-width: 1200px; 
    margin: 0 auto; 
}

.footer-section {
    flex: 0; 
    min-width: 200px; 
    margin: 10px; 
}

.footer-section ul li a:hover {
    text-decoration: underline; 
} 

.social-icons {
    display: flex; 
    gap: 10px; 
}

.social-icons a {
    color: #ffffff;
    font-size: 24px; 
    transition: color 0.3s; 
}

.social-icons a:hover {
    color: #61dafb; 
}

.footer-bottom {
    text-align: center;
    padding: 10px 0; 
    background-color: #1f2125;  
    margin-top: 20px; 
}

.footer-section.address {
    padding: 10px;
}

.footer-section.address h3 {
    margin-bottom: 10px;
}

.footer-section.address p {
    margin: 5px 0;
}

@media (max-width: 480px) {
    footer {
        width: 100%;
    }
}
