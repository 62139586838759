/* ScrollingCompanies.css */
.scrolling-container {
    width: 70%;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .scrolling-content {
    display: flex;
    justify-content: center;
    white-space: nowrap;
    align-items: center;
    text-align: center;
    animation: scroll-left 20s linear infinite;
    gap: 90px;
  }
  
  .company-item {
    display: flex;
    align-items: center;
    gap: 10px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
  }
  
  .company-logo {
    width: 50px;
    height: auto;
    margin-right: 10px; 
    mix-blend-mode: lighten;
  }
  
  .company-name {
    font-size: 20px;
    font-weight: bold;
  }
  
  @keyframes scroll-left {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  @media (max-width: 480px) {
    .scrolling-container {
      width: 90%;
    }
  }
  