body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  /* background-color: #9e7c5f; */
  background: linear-gradient(to right, #f0e5dd, #f8ebe2, #f0e4d4);
}

.appContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  z-index: 10;
}

.mainContent {
  flex: 1;
  padding: 20px;
}

.footer {
  z-index: 10;
}

.image-container {
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;
  margin-bottom: 40px;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;
}

.overlay-text h1 {
  margin: 0;
  font-size: 2.5rem;
}

.overlay-text p {
  margin: 10px 0 0;
  font-size: 1.2rem;
}

/* About.css */

.aboutPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px;
  /* background-color: #f8f9fa; */
  width: 100%;
  margin-top: 60px;
}

.content {
  margin: 20px auto;
  max-width: 900px;
}

.section {
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.section h2 {
  font-size: 2rem;
  color: #171515;
  margin-bottom: 10px;
  font-weight: bold;
}

.section p {
  font-size: 1.1rem;
  color: #0a0a0a;
  line-height: 1.6;
}

.mission {
  /* background: #e8f1d9; */
  background: linear-gradient(135deg, #dbad89 0%, #e67716 100%);
}

.vision {
  /* background: #d9e8f1; */
  background: linear-gradient(135deg, #e67716 0%,  #dbad89 100%);
}

.what-we-do {
  /* background: #f1e8d9; */
  background: linear-gradient(135deg, #dbad89 0%, #e67716 100%);
}

@media (max-width: 480px) {
  .aboutPage {
    padding: 10px; /* Reduce padding for smaller screens */
    margin-top: 0; /* Remove top margin to fit the screen properly */
    width: 100%;
  }

  .content {
    margin: 0 auto;
    padding: 0 10px; /* Add padding to ensure content is not too close to the edges */
    max-width: 100%; /* Ensure full width utilization */
  }

  .section {
    margin-bottom: 50px;
    padding: 10px;
    width: 100%;
  }

  .section h2 {
    font-size: 1.5rem; /* Adjust font size */
  }

  .section p {
    font-size: 1rem; /* Adjust font size */
  }

  .overlay-text {
    width: 90%; /* Adjust width for mobile screens */
    padding: 15px; /* Adjust padding */
    font-size: 1rem; /* Adjust font size for mobile */
  }

  .overlay-text h1 {
    font-size: 1.8rem; /* Adjust font size for mobile */
  }

  .overlay-text p {
    font-size: 1rem; /* Adjust font size for mobile */
    color: black; /* Ensure good contrast */
  }
}
