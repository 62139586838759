/* JavaCoursePage.css */
.java-course-page {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding: 20px;
  margin-top: 42px;
  /* color: #333; */
}

.banner {
  text-align: center;
  padding: 30px;
  /* background: linear-gradient(135deg, #007bff 0%, #00c6ff 100%); */
  color: #181301;
  border-radius: 10px;
  margin-bottom: 30px;
  position: relative;
}

.banner h1 {
  margin: 0;
  font-size: 2.5em;
  font-weight: bold;
}

.brochure-button {
  margin-top: 15px;
  padding: 12px 25px;
  background-color: #fff;
  color: #181301;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  transition: background-color 0.3s, color 0.3s;
}

.brochure-button:hover {
  /* background-color: #007bff; */
  background: linear-gradient(135deg, #df823a 100%, #a5744a 100%);
  color: #eee6e6;
  font-weight: 500;
}

.rating {
  margin-top: 15px;
}

.star {
  color: #8a6d6d;
  font-size: 1.8em;
  margin: 0 2px;
}

.banner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    /* background: #ffd580; */
  background: linear-gradient(135deg, #df823a 100%, #a5744a 100%);
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .banner-left {
    flex: 1;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  
  .banner-left h1 {
    margin: 0;
    font-size: 2em;
  }
  
  .banner-left .rating {
    margin-top: 10px;
  }
  
  .banner-left .star {
    color: #663402;
    font-size: 1.5em;
  }
  
  .banner-right {
    flex: 1;
    max-width: 400px;
  }
  
  .form-container {
    background: #ffffff;
    color: #0c0b0b;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .info-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .info-form input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1em;
  }
  
  .readonly-field {
    background-color: #f4f4f4;
    cursor: not-allowed;
  }
  
  .submit-button {
    padding: 10px;
    /* background-color: #007bff; */
  background: linear-gradient(135deg, #df823a 100%, #a5744a 100%);
  font-weight: bold;
    color: #0c0b0b;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }

section {
  margin-bottom: 30px;
}

section h2 {
  font-size: 2em;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 15px;
}

section p {
  font-size: 1.1em;
  margin-bottom: 15px;
}

section ul {
  list-style: none;
  padding: 0;
}

section ul li {
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

.card-header {
  font-size: 1.5em;
  font-weight: bold;
  /* color: #007bff; */
  color: rgb(10, 10, 10);
  margin-bottom: 15px;
}

.card-body {
  font-size: 1.1em;
  line-height: 1.6;
  position: relative;
  padding-left: 30px;
}

.card ul {
  padding-left: 20px;
  margin-top: 10px;
}

.card ul li {
  position: relative;
  padding-left: 35px;
  margin-bottom: 10px;
  line-height: 1.6;
}

.card-body li::before {
  content: "\2713";
  position: absolute;
  left: 0.8%;
  top: 50%;
  transform: translateY(-50%);
  color: #f39c12;
  font-size: 1.2em;
}

@media (min-width: 768px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .col {
    flex: 1;
    min-width: 300px;
  }
}

@media (max-width: 480px) {
  .banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    width: 100%;
    height: 594px;
  }

  .banner-right {
    width: 100%;
  }

  .brochure-button {
    width: 90%;
    margin-bottom: 30px;
  }

  .card {
    width: 100% !important; /* Increase width of the card */
    margin-left: 60px; /* Center-align the card */
    padding: 20px; /* Adjust padding if necessary */
    box-sizing: border-box; 
  }

  .card-header {
    font-size: 20px;
  }

  .card-body {
    font-size: 16px;
  }
}
