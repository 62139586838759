body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.full-width-container {
  width: 100%;
  margin-top: 60px;
}

.left {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  /* background-color: #003366; */
  background: linear-gradient(135deg, #df823a 100%, #a5744a 100%);
  color: rgb(19, 14, 14);
  height: 100vh;
}

.text-content {
  flex: 1;
  padding: 20px;
  text-align: center;
  margin: auto
}

.text-content h1 {
  margin: 0;
}

.text-content .highlight {
  color: #ffd700;
}

.text-content p {
  margin: 20px 0;
}

.download-button {
  background-color: #ff8c00;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.right-form {
  width: 500px; /* Adjust as needed */
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-right: 3%;
  margin-top: 3%;
}

.enquiry-form {
  display: flex;
  flex-direction: column;
}

.enquiry-form h2 {
  margin-bottom: 20px;
}

.enquiry-form input, 
.enquiry-form select, 
.enquiry-form textarea {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.enquiry-form textarea {
  resize: none;
}

.enquiry-form button {
  /* background-color: #003366; */
  background: linear-gradient(135deg, #df823a 100%, #a5744a 100%);
  color: rgb(14, 13, 13);
  font-weight: bold;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}

/* CorporateTraining.css */

.wholeCorporateTextDiv {
  margin-top: 40px;
  padding: 20px;
  /* background-color: #e5ecf7; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 60px;
}

.firstDiv {
  width: 80%; 
  margin: auto;
  padding: 30px;
  background-color: #f1f4f6; 
  border-radius: 8px; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
}

.heading {
  margin-bottom: 20px;
}

.heading h2 {
  margin: 0;
  color: #090a0a; 
  font-size: 24px;
  text-transform: uppercase;
  font-weight: bold;
}

.line {
  width: 570px;
  height: 4px;
  background-color: #ffd700; 
  margin: 10px auto;
}

.line1 {
  width: 310px;
  height: 4px;
  background-color: #ffd700; 
  margin: 10px auto;
}

.line2 {
  width: 430px;
  height: 4px;
  background-color: #ffd700; 
  margin: 10px auto;
}

.line3 {
  width: 730px;
  height: 4px;
  background-color: #ffd700; 
  margin: 10px auto;
}

.line4 {
  width: 500px;
  height: 4px;
  background-color: #ffd700; 
  margin: 10px auto;
}

.text {
  font-size: 16px;
  color: #333333; 
  line-height: 1.6;
  text-align: left;
}

ul {
  line-height: 30px;
}

ul strong {
  color: black;
}

@media (max-width: 768px) {
  .heading h3 {
    font-size: 20px;
  }

  .text {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .wholeCorporateTextDiv {
    padding: 10px;
  }

  .firstDiv {
    padding: 15px;
  }

  .heading h3 {
    font-size: 18px;
  }

  .text {
    font-size: 13px;
  }
}

@media (max-width: 780px) {
  .left {
    flex-direction: column;
  }

  .right-form {
    width: 100%;
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .full-width-container {
    width: 100%;
  }
  .wholeCorporateTextDiv {
    width: 100%;
    padding-top: 80px;
  }
  .heading h2 {
    font-size: 20px;
  }
  .line {
    width: 190px;
  }
}
