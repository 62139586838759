/* .card-container {
    display: flex;
    flex-wrap: wrap;
    margin: -15px;
    margin-top: 3%;
  }
  
  .card-item {
    max-width: 100%;
    padding: 15px; 
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-direction: row;
  } */

  .card-container {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping to new rows */
    gap: 15px; /* Space between cards */
    margin-top: 3%;
    justify-content: center; /* Center the items horizontally */
  }
  
  /* Styles for individual card items */
  .card-item {
    flex: 1 1 calc(33.333% - 30px); /* 3 items per row minus gap space */
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    justify-content: center;
  }
  
  /* Card styles */
  .card {
    border: 1px solid #ddd; /* Optional: Add border */
    border-radius: 8px; /* Rounded corners */
    padding: 20px;
    background-color: #fff; /* Background color */
    text-align: center;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Shadow for depth */
  }
  
  .card-icon {
    width: 60px; /* Adjust size as needed */
    height: auto;
  }
  
  .card-title {
    font-size: 1.2rem;
    margin: 10px 0;
  }
  
  .card-text {
    font-size: 0.9rem;
    color: #555;
  }  
  
  /* Media queries for responsive grid */
  @media (min-width: 576px) {
    .card-item {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  
  @media (min-width: 768px) {
    .card-item {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
  }
  
  @media (min-width: 992px) {
    .card-item {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  
  /* Card styles */
  .card {
    border: 2px solid #f0f0f0;
    border-radius: 10px;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    border: 1px solid black;
  }
  
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .card-body {
    padding: 20px;
  }
  
  .icon-container {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .card-icon {
    width: 100%;
    height: auto;
    transition: transform 0.6s;
  }
  
  .card:hover .card-icon {
    transform: rotate(360deg);
  }
  
  .card-title {
    color: #ff8c00;
    padding-top: 10%;
  }
  
  .card-text {
    font-size: 14px;
    color: #555;
  }

  @media (max-width: 480px) {
    .card {
      margin-left: 0;
    }
  }
  