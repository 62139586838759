.process-flow-container {
    position: relative;
    text-align: center;
  }
  
  .process-flow-image {
    width: 100%;
    height: auto;
    mix-blend-mode: multiply;
  }
  
  .process-flow-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10%;
  }
  
  .step {
    text-align: center;
    color: #000; /* Adjust this color as needed */
  }
  
  .step-label {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .step-title {
    font-size: 14px;
  }
  
  @media (max-width: 768px) {
    .process-flow-text {
      flex-direction: column;
      padding: 0;
    }
  
    .step {
      margin-bottom: 20px;
    }
  
    .step:last-child {
      margin-bottom: 0;
    }
  }
  