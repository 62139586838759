/* Testimonial.css */
.testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  max-width: 500px;
  margin-right: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.testimonialImage {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-bottom: 10px;
}

.testimonialStatement {
  font-style: italic;
  color: #555;
  text-align: center;
}
