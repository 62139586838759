/* Basic Reset and Viewport Meta Tag */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; 
  font-family: Arial, sans-serif;
  box-sizing: border-box; 
}

/* Initial hidden state for animations */
.hidden {
  opacity: 0;
  transform: translateY(20px);
}

.courseOneLink {
  text-decoration: none;
}

.courseOneLink:hover  {
  text-decoration: none;
}

/* Hero Section */
.heroDiv {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.heroSection {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1200px; /* Added max-width */
  height: auto; /* Adjusted height for responsive */
  align-items: center;
  flex-direction: row;
  gap: 20px;
}

.heroSection .content {
  font-size: 40px;
  text-align: left;
  line-height: 47px;
  letter-spacing: normal;
  color: #272c37;
  width: 100%;
  max-width: 55%;
  box-sizing: border-box;
}

.heroSection .edImage {
  margin-left: 15%;
  max-width: 100%; /* Ensure image scales */
  mix-blend-mode: multiply;
}

.homeEduPic {
  mix-blend-mode: multiply;
  border-radius: 50%;
  margin-top: 40px;
}

/* Hero Section Animation */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.heroSection.visible {
  /* animation: slideIn 3s ease-out forwards; */
  animation: zoomIn 1s ease-out forwards;
}

/* Courses Perks */
.coursesPerks {
  width: 100%;
  height: auto;
  background-color: #0a191e;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 90px;
  margin-top: 70px;
  margin-bottom: 90px;
  padding: 0 10px; 
}

.contentDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
  color: rgb(217, 89, 3);
}

/* Courses Perks Animation */
@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.coursesPerks.visible {
  animation: zoomIn 1s ease-out forwards;
}

/* Courses Offered */
.coursesDiv {
  margin-top: 50px;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px; /* Added max-width */
  text-align: center;
  padding: 0 10px; /* Added padding for better spacing */
}

.coursesDiv h3 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.underline {
  width: 20%;
  height: 3px;
  background-color: orange;
  margin: 0 auto;
}

/* Course Layout */
.lineOneCourse,
.lineTwoCourse {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 2%;
  margin-top: 20px;
}

.courseOne {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid black;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 5px 4px 8px 5px rgba(227, 230, 219, 0.4);
  width: 300px;
  max-width: 90%; /* Added max-width */
  padding: 20px;
  box-sizing: border-box;
  transition: transform 0.3s;
}

.courseOne p {
  margin-top: 20px;
}

/* Course Animation */
@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: translateY(-1000px);
  }
  60% {
    opacity: 1;
    transform: translateY(30px);
  }
  80% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.courseOne.visible {
  animation: bounceIn 6s ease-out forwards;
}

.courseOne:hover {
  transform: scale(1.09);
  box-shadow: 0 4px 8px rgba(215, 53, 53, 0.1);
  border: none;
  background-color: rgb(218, 208, 199);
  color: orange;
  font-weight: bold;
}

/* Student Working Section */
.studentWorkingSection {
  margin-top: 90px;
  margin-bottom: 90px;
  text-align: center;
  padding: 0 10px; /* Added padding for better spacing */
}

.studentWorkingSection h3 {
  text-transform: uppercase;
}

.underline1 {
  width: 20%;
  height: 3px;
  background-color: orange;
  margin: 0 auto;
}

/* Aligning companies in the center */
.scrollingCompanies {
  display: flex;
  justify-content: center;
  overflow-x: auto;
  gap: 10px;
  padding: 0 10px; /* Added padding to prevent overflow */
}

/* Happy Students */
.happyStudents {
  margin-top: 90px;
  margin-bottom: 90px;
  text-align: center;
  padding: 0 10px; /* Added padding for better spacing */
}

.happyStudents h3 {
  font-size: 2rem;
}

.happyStudents .underline {
  width: 20%;
  height: 3px;
  background-color: orange;
  margin: 0 auto;
}

/* Enroll Section */
.enrollSection {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  /* background-color: #edecec; */
  /* background: linear-gradient(to left, #d4cbc5, #d9cac0, #d6c1a5); */
  /* background: linear-gradient(135deg, #dbad89 0%, #e67716 100%); */
  border-radius: 8px;
  text-align: center;
  width: 90%;
  margin: 0 auto;
  gap: 20px;
  box-sizing: border-box; 
  mix-blend-mode: multiply;
  margin-bottom: 3%;
}

.contentSection {
  width: 100%;
  max-width: 600px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* box-shadow: 1px 1px 1px rgba(237, 191, 152, 0.9);   */

}

.call {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.imageSection {
  width: 100%;
}

.enrollSection.visible {
  /* animation: slideIn 1s ease-out forwards; */
  animation: zoomIn 3s ease-out forwards;

}

/* Placement Partners */
.placementPartners {
  margin-top: 90px;
  margin-bottom: 90px;
  text-align: center;
  padding: 0 10px; /* Added padding for better spacing */
}

/* Responsive Design */
@media (max-width: 768px) {
  .heroSection {
    flex-direction: column;
    height: auto;
  }

  .heroSection .content {
    width: 100%;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
  }

  .heroSection .edImage {
    margin-left: 0;
    margin-top: 20px;
  }

  .coursesPerks {
    flex-direction: column;
    height: auto;
    padding: 10px; /* Added padding for better spacing */
  }

  .courseOne {
    width: 90%;
    margin-bottom: 20px;
  }

  .scrollingCompanies {
    flex-direction: column;
    align-items: center;
    padding: 0; /* Remove horizontal padding */
  }

  .enrollSection {
    width: 100%;
    padding: 20px 10px;
  }

  .contentSection {
    width: 100%;
  }

  .imageSection {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .heroSection .content {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0px;
    margin-top: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .edImage {
    margin-top: 0px;
    width: 100%;
  }

  .homeEduPic {
    margin-left: 2px;
    height: auto;
    margin-top: 0px;
  }

  .coursesPerks, .coursesDiv, .studentWorkingSection, .happyStudents, .enrollSection {
    width: 100%;
    padding: 10px;
  }

  .coursesPerks {
    display: flex;
    gap: 1px;
    width: 90%;
    margin: auto;
    border-radius: 10px;
    margin-top: 18%;
  }

  .hr {
    color: white;
    background-color: orange;
    height: 1px;
    width: 100%;
  }

  .coursesDiv {
    margin-top: 50px;
  }

  .courseOne {
    width: 100%;
    max-width: 300px;
  }

  .scrollingCompanies {
    overflow-x: scroll;
    white-space: nowrap;
    padding: 0;
  }

  .underline {
    display: none;
  }

.imageSection {
  /* display: none; */
  mix-blend-mode: multiply;
}

.paragraph {
  margin-top: 20px;
}

.enrollSection {
  display: flex;
  flex-direction: column;
  width: 95%;
  background-color: rgb(248, 246, 243);
  /* background: linear-gradient(to left, #d4cbc5, #d9cac0, #d6c1a5); */
  margin-bottom: 10%;
}

.call {
  color: orange;}
}
