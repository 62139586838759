/* StudentList.css */
.testimonialContainer {
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 20px;
  }
  
  .testimonial {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 600px;
    width: 100%;
  }
  
  .testimonialImage {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .testimonialStatement {
    font-size: 1rem;
    color: #333;
  }

  @media (max-width: 480px) {
    .testimonialContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-left: 20px;
    }
  }
  