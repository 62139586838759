/* OurTeam.css */

.team-section {
    padding: 10px 0;
    background: linear-gradient(to right, #e1e1eb, #dacdc4, #e9dad0);
  }
  
  .section-title {
    font-size: 34px;
    margin-bottom: 20px;
    margin-top: 29px;
    color: orange;
  }
  
  .section-subtitle {
    font-family: 'Times New Roman', Times, serif;
    color: #666;
    font-size: 18px;
  }
  
  .team-card {
    margin-bottom: 30px;
    text-align: center;
  }
  
  .team-member {
    background: #e9d7d7;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .team-member:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  }
  
  .team-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 2px solid #eee;
  }
  
  .team-content {
    padding: 20px;
    text-align: center;
  }
  
  .team-name {
    font-size: 22px;
    margin: 10px 0;
    font-weight: bold;
  }
  
  .team-role {
    color: #555;
    font-size: 16px;
    margin-bottom: 15px;
  }
  
  .bio {
    font-size: 14px;
    color: #666;
    margin-bottom: 15px;
  }
  
  .skills {
    font-size: 14px;
    color: #444;
    margin-bottom: 20px;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .social-icon {
    color: #333;
    font-size: 24px;
    transition: color 0.3s;
  }
  
  .social-icon:hover {
    color: #0077b5; 
  }

  .social-icons a:nth-child(1) .social-icon:hover {
    color: #E4405F; 
  }
  
  .social-icons a:nth-child(2) .social-icon:hover {
    color: #1DA1F2; 
  }
  
  .social-icons a:nth-child(3) .social-icon:hover {
    color: #0077B5;
  }

  @media (max-width: 480px) {
    .team-section {
      width: 100%;
    }
  }
  