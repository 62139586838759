/* General header styles */
.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure the header is on top */
  background-color: #f2e9d7; /* Background color for visibility */
}

/* Logo styles */
/* .logo img {
  height: 80px;
  width: 90%;
  margin-left: 20px;
  border-radius: 50%;
} */

.logo {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.logo span {
  font-family: 'Arial', sans-serif; 
  font-size: 36px;
  font-weight: bold;
  color: #e1490e; 
  text-shadow: 2px 2px 4px rgba(67, 232, 7, 0.2); 
  margin-left: 35%;
  margin-bottom: 0px;
}

.logoLine {
  display: block;
  width: 100%; 
  height: 3px; 
  background-color: black;
  border: none;
margin-top: 0px;
margin-left: 35%;
}

/* Header links container styles */
.headerLinksDiv {
  display: flex;
  justify-content: center;
  gap: 30px;
  width: calc(100% - 70px); /* Adjust width to make room for the logo */
  position: relative;
  z-index: 1000; /* Ensure links are on top of other elements */
  font-weight: bold;
}

/* Link styles */
.headerLinksDiv a {
  text-decoration: none;
  color: black;
}

/* Dropdown styles */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Button that triggers the dropdown */
.dropdown button {
  font-size: 16px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: transparent;
  margin: 0;
  padding: 0;
}

/* Dropdown icon */
.dropdown button::after {
  content: "▼";
  margin-left: 6px;
  font-size: 12px;
  margin-top: 3px;
}

/* Dropdown content */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1001; /* Ensure dropdown is above other elements */
  border-radius: 10px;
  margin-top: 5px;
  font-weight: 100;
}

/* Show dropdown content when active */
.dropdown-content.active {
  display: block;
}

/* Dropdown links */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Styles for the hamburger menu icon */
.menu-icon {
  display: none; /* Hidden by default */
  flex-direction: column;
  cursor: pointer;
  margin-right: 10px;
}

/* Hamburger menu lines */
.menu-icon .line {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 2px 0;
}

/* Main content padding */
.main-content {
  padding-top: 60px; /* This should match the height of the header */
}

/* Responsive styles for tablets and mobile devices */
@media (max-width: 1036px) {
  .headerLinksDiv {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: linear-gradient(to right, #f0e5dd, #f8ebe2, #f0e4d4);
    position: absolute;
    top: 60px;
    width: 100%;
    display: none;
    z-index: 1000; /* Ensure it is on top of other elements when visible */
  }

  .headerLinksDiv.active {
    display: flex;
  }

  .menu-icon {
    display: flex; /* Show hamburger menu icon on mobile */
  }

  .header {
    justify-content: space-between; /* Ensure hamburger menu is on the right */
  }
}
