/* RegistrationOnline.css */

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #f0f2f5;
  }
  
  .appContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .header {
    z-index: 10;
  }
  
  .mainContent {
    flex: 1;
    padding: 20px; 
  }
  
  .footer {
    z-index: 10;
  }
  
  .registration {
    width: 80%;
    padding: 40px;
    margin: 90px auto;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    max-height: 90%;
  }
  
  .heading {
    font-size: 28px;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 10px;
    letter-spacing: 1px;
  }
  
  .subheading {
    font-size: 16px;
    color: #666;
    text-align: center;
    margin-bottom: 30px;
    letter-spacing: 0.5px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .horizontal-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .form label {
    margin-bottom: 15px;
    font-size: 14px;
    color: #333;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .form input,
  .form select {
    width: 100%;
    padding: 12px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    background: #f9f9f9;
    transition: background 0.3s ease, border-color 0.3s ease;
  }
  
  .form input:focus,
  .form select:focus {
    background: #e9f7fe;
    border-color: #007bff;
    outline: none;
  }
  
  .form button {
    width: 100%;
    padding: 15px;
    border: none;
    border-radius: 5px;
    /* background: #007bff; */
  background: linear-gradient(135deg, #df823a 100%, #a5744a 100%);
    color: rgb(4, 4, 4);
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.3s ease;
  }
  
  /* .form button:hover {
    background: #0056b3;
    transform: scale(1.02);
  } */

  @media (max-width: 480px) {
    .registration {
      width: 95%;
      margin-left: 10px;
      margin-top: 80px;
      margin-bottom: 30px;
    }
    .heading {
      color: orange;
    }
  }
  