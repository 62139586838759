/* Contact.css */

.contact-form-container {
  padding: 40px;
  max-width: 700px;
  margin: 0 auto;
  margin-top: 6%;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
}

.contact-form-heading {
  font-size: 2.4em;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 500;
}

.contact-form-subheading {
  margin-bottom: 20px;
  color: #666;
  text-align: center;
  font-size: 0.9em;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.form-row label {
  flex: 1;
  min-width: 200px;
  font-weight: 500;
  color: #333;
}

.contact-form-input,
.contact-form-select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: #fafafa;
  transition: border-color 0.3s, box-shadow 0.3s;
  height: 42px;
}

.contact-form-textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: #fafafa;
  transition: border-color 0.3s, box-shadow 0.3s;
  height: 50px;
}

.contact-form-input:focus,
.contact-form-select:focus,
.contact-form-textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
  outline: none;
}

.contact-form-textarea {
  resize: vertical;
}

.contact-form-button {
  padding: 12px 24px;
  /* background: #007bff; */
  background: linear-gradient(135deg, #df823a 100%, #a5744a 100%);
  color: #0c0a0a;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.1em;
  font-weight: 600;
  text-transform: uppercase;
  transition: background 0.3s, transform 0.3s;
  margin-top: 20px;
}

.contact-form-button:hover {
  background: #0056b3;
  transform: translateY(-2px);
}

.contact-form-button:active {
  transform: translateY(0);
}

.contact-form-label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.contact-form-input,
.contact-form-select,
.contact-form-textarea {
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.contact-form-input::placeholder,
.contact-form-select::placeholder,
.contact-form-textarea::placeholder {
  color: #999;
}

@media (max-width: 480px) {
  .contact-form-container {
    width: 97%;
    margin-top: 100px;
    margin-left: 7px;
  }
  .contact-form-heading, strong {
    color:  orange;
  }
}
