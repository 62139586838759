/* AmanFace.css */
.amand-image {
    width: 150px; 
    height: 150px; 
    border-radius: 50%;
    object-fit: cover; 
    border: 3px solid #ddd; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
    transition: transform 0.3s, box-shadow 0.3s;
    margin-top: 20px;
  }
  
  .amand-image:hover {
    transform: scale(1.05); 
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); 
  }
  