html, body {
    height: 100%;
    margin: 0;
  }
  
  .jobs-container {
    height: 100vh; 
    margin-top: 90px; 
    padding: 0;
  }
  